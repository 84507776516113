<template>
    <div class="section-newslist">
        <div class="container">
            <div class="section-newslist__content">
                <ul class="section-newslist__list">
                    <li class="section-newslist__post" v-for="(post, index) in posts" :key="index">
                        <div class="image">
                            <a :href="`/news/`+post.slug">
                                <img :class="'lazyload' + ' ' +post.slug" :data-src="webp(post.better_featured_image?post.better_featured_image.media_details.sizes.medium.source_url:'img/no-image-available.png')" :alt="post.title.rendered">
                            </a>
                        </div>
                        <div class="content">
                            <div class="date"><img width="22" src="../../../public/img/news/table-grid.svg" alt=""> {{ post.date | formatDate('DD/MM/YYYY') }}</div>
                            <h3 class="title"><a :href="`/news/`+post.slug" v-html="post.title.rendered"></a></h3>
                        </div>
                    </li><!--/ .section-newslist__post -->
                </ul><!--/ .section-listnews_list -->

                <nav>
                    <ul class="pagination">
                        <li class="page-item" :class="prev_page() !== 0?'active':'disabled'" v-on:click="fetch_prev_page()"><span class="page-link" v-html="$t(`pagination.previous`)"></span></li>
                        <li class="page-item" :class="{active: current_page == index}" v-for="index in total_pages" :key="index" v-on:click="current_page=index;fetch_posts(locale)"><span class="page-link">{{index}}</span></li>
                        <li class="page-item" :class="next_page() !== 0?'active':'disabled'" v-on:click="fetch_next_page()"><span class="page-link" v-html="$t(`pagination.next`)"></span></li>
                    </ul>
                </nav>
            </div><!--/ .section-listnews__content -->
        </div><!--/ .container -->
    </div>
</template>

<script>
    import {Post} from "../../mixins/post"

    export default {
        name: "NewsList",
        mixins: [Post],
        props: {
            locale: String
        },
        data() {
            return {
                page: 'news',
                numberposts: 3,
            }
        },
        methods: {
            fetch_next_page: function() {
                if(this.next_page() !== 0) {
                    this.current_page = this.next_page();
                    this.fetch_posts(this.locale);
                }
            },
            fetch_prev_page: function() {
                if(this.prev_page() !== 0) {
                    this.current_page = this.prev_page();
                    this.fetch_posts(this.locale);
                }
            }
        }
    }
</script>

<style lang="scss">
    @import '../../assets/scss/mixin/_mixin';

    $sm: 768;
    $md: 992;
    $lg: 1200;

    .section-newslist {
        &__content {
            padding-top: 4.5rem;
            padding-bottom: 6rem;
            background: url("../../../public/img/news/bg/bg-newslist-x.jpg") repeat-x left top;

            @include media(set-min-w, $sm) {
                padding-top: 6.7rem;
            }
            @include media(set-min-w, $md) {
                padding-top: 8.7rem;
            }

            a {
                color: inherit;

                &:hover {
                    text-decoration: underline;
                }
            }

            .image {
                display: none;
                @include media(set-min-w, $sm) {
                    display: block;
                    flex: 0 0 23rem;
                    max-width: 23rem;
                }

                img {
                    width: 100%;
                }
            }

            .content {
                padding: 0 1rem;
                @include media(set-min-w, $sm) {
                    flex-grow: 1;
                    max-width: 87rem;
                    padding: 1rem 2.8rem;
                }
                @include media(set-min-w, $md) {
                    padding-left: 3.8rem;
                }
            }

            .date {
                display: flex;
                align-items: center;
                margin-bottom: .5rem;
                color: #202020;
                font-size: 1.4rem;
                @include media(set-min-w, $sm) {
                    font-size: 1.7rem;
                }

                img {
                    max-width: 30px;
                    margin-right: 1rem;
                    filter: invert(.1);
                    @include media(set-min-w, $sm) {
                        filter: invert(.3);
                    }
                }
            }

            .title {
                color: #5c5c5c;
                font-size: 1.4rem;
                font-weight: 600;
                overflow: hidden;
                @include media(set-min-w, $sm) {
                    line-height: 3.5rem;
                    font-size: 2rem;
                }
                @include media(set-min-w, $md) {
                    font-size: 2.8rem;
                    max-height: 7.5rem;
                }
            }
        }

        &__list {
            @include media(set-min-w, $md) {
                padding-bottom: 2rem;
            }
        }

        &__post {
            padding-bottom: 1.2rem;
            margin-bottom: 2.4rem;
            border-bottom: 1px solid #999;
            @include media(set-min-w, $sm) {
                display: flex;
                align-items: center;
                padding-bottom: 0;
                margin-bottom: 3.7rem;
                border-bottom: 0;
            }

            .item {
                @include media(set-min-w, $sm) {
                    display: flex;
                    align-items: center;
                    flex: 0 0 100%;
                }
            }
        }

        .pagination {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem 0;
            @include media(set-min-w, $sm) {
                padding-top: 2rem;
                padding-bottom: 2rem;
            }

            &::before,
            &::after {
                content: '';
                width: 2.2rem;
                height: .4rem;
                background: url("../../../public/img/news/arrow-pagination.png") no-repeat center center;
            }

            &::before {
                transform: rotateX(-180deg);
            }

            &::after {
                transform: rotateY(-180deg);
            }

            .page-item {
                padding: 0 1rem;
                color: #bababa;
                font-size: 1.6rem;
                font-weight: 700;
                text-transform: uppercase;
                cursor: pointer;

                &.active {
                    color: #353535;

                    span {
                        text-decoration: underline;
                    }
                }
            }

            .page-link {
                color: inherit;
            }
        }
    }
    .webp {
        .section-newslist {
            &__content {
                background: url("../../../public/img/news/bg/bg-newslist-x.webp") repeat-x left top;
            }
            .pagination {
                &::before,
                &::after {
                    background: url("../../../public/img/news/arrow-pagination.webp") no-repeat center center;
                }
            }
        }
    }
</style>